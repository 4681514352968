import React, { Fragment, useEffect } from "react"
import FormatoDelProyecto from '@/components/FormatoDelProyecto'
import LineaDeTiempo from '@/components/lineaDeTiempo'
import AOS from "aos"
import "aos/dist/aos.css"
import Logo from '@/images/new/landingPageEs/logo.svg'
import Objetivos from '@/images/new/landingPageEs/objetivos.png'
import Participante2 from '@/images/new/landingPageEs/participante-2.png'
import Dot from '@/images/new/landingPageEs/dot.svg'
import Sapientia from '@/images/new/landingPageEs/sapientia.svg'
import Vertere from '@/images/new/landingPageEs/vertere.svg'
import Fractal from '@/images/new/landingPageEs/fractal.svg'

// function onChange(value) {
//   console.log("Captcha value:", value);
// }

const Finalistas = ({}) => {
	useEffect(() => {
		AOS.init({
			duration : 2000
		});
	}, []);
  return (
    <Fragment>
			<section className="pageLanding" style={{ background: 'linear-gradient(79.8deg, #02F2F2 -0.55%, #028BF2 11.49%, #026FC2 21.97%, #3501AB 77.48%, #5D16FE 94.24%, #7C16FE 100%)', paddingBottom: 150, paddingTop: 46 }}>
        <div className="container">
					<div className="row text-center" data-aos="fade-right" data-aos-once="true">
						<img src={Logo} className="MR-M-16 text-center" />
            <div className="title-header-es">La Olimpiada del Futuro dio un paso adelante y llegó al Paraguay.</div>
            <div className="register-btn" style={{ marginTop: 52 }}>
                  <a href='https://forms.gle/ziaY7HrEDbb9EodF7' target='_blank' className="inscreva-se_btn" style={{ padding: '20px 35px', fontWeight: 600 }}>¡Envíe su proyecto aquí!</a>
								</div>
					</div>
        </div>
      </section>
			<section style={{ background: '#FFFFFF', paddingTop: '88px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6" data-aos="fade-right" data-aos-once="true">
              <h2 className="h2-title">Sapientia</h2>
              <p className="p-content"><b>Sapientia is a project-based Olympiad that seeks to spark high school students’ critical thinking, creativity, and bias to action.</b> It introduces them to current global challenges and guides them to create practical solutions to the problems around them.</p>
              <p className="p-content">HighTechU is partnering with Sapientia to bring this exciting program to Canadian students in 2023.</p>
              <p className="p-content">And the best, all this for free!</p>
            </div>
            <div className="col-lg-6" data-aos="fade-left" data-aos-once="true">
              <img className="img-size" src={Objetivos} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </section>
			<section style={{ background: '#026FC2', padding: '72px 0' }} data-aos="fade-up" data-aos-once="true">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
							<p className="cta-2-paragraph">"Sapientia existe para ti, el estudiante, para que inventes el conocimiento que quieres aprender."</p>
              <p className="cta-3-paragraph" style={{ marginBottom: 0 }}>Únete al futuro. ¡Saca tu mate!, ¡apunta-te a Sapientia!</p>
            </div>
          </div>
        </div>
      </section>
			<section style={{ background: '#FFFFFF', paddingTop: '88px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7" style={{ marginBottom: 90 }} data-aos="fade-right" data-aos-once="true">
							<h2 className="heading-section">¿Cómo aplicar?</h2>
              <p className="land-2-paragraph" style={{ marginBottom: 32 }}>La inscripción es gratuita: ¡totalmente gratis! Y se hace nel pulsador abajo﻿</p>
              <a href='https://forms.gle/ziaY7HrEDbb9EodF7' target='_blank' className="inscreva-se_btn" style={{ padding: '20px 35px', fontWeight: 600 }}>¡Envíe su proyecto aquí!</a>
							<h2 className="heading-section" style={{ marginTop: 42 }}>¿Es una prueba presencial?</h2>
							<p className="land-2-paragraph" style={{ marginBottom: 32 }}>La Olimpiada ocurre en el formato digital</p>
              <p className="land-2-paragraph" style={{ marginBottom: 32 }}>Pero la final, con la presentación de los finalistas, se realiza de forma presencial, al final de cada año.</p>
              <h2 className="heading-section">Pero, ¿Cómo funciona entonces?</h2>
							<p className="land-2-paragraph" style={{ marginBottom: 32 }}><img src={Dot} alt='' style={{ marginRight: 12 }} />Armas un equipo en tu escuela.</p>
							<p className="land-2-paragraph" style={{ marginBottom: 32 }}><img src={Dot} alt='' style={{ marginRight: 12 }} />Luego, junto con maestros y colegas, discuta cómo resolver un problema en su ciudad o región, utilizando la Agenda 2030 de la ONU.</p>
							<p className="land-2-paragraph" style={{ marginBottom: 0 }}><img src={Dot} alt='' style={{ marginRight: 12 }} />Tu equipo armará una especie de plan de negocios, un anteproyecto autoral que utiliza tus conocimientos para resolver problemas reales de tu vida diaria.</p>
            </div>
            <div className="col-lg-5" style={{ display: 'flex', alignItems: 'flex-end' }} data-aos="fade-left" data-aos-once="true">
              <div>
								<img className="img-size" src={Participante2} style={{ width: '100%' }} />
							</div>
            </div>
          </div>
        </div>
      </section>
    	<FormatoDelProyecto />
			<LineaDeTiempo />
			<section style={{ background: '#FFFFFF', paddingBottom: 102 }} data-aos="zoom-in-down" data-aos-once="true">
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
						<div className="col-lg-2 col-sm-12 text-center MB-M-48">
							<a href="/"><img src={Sapientia} /></a>
						</div>
						<div className="col-lg-2 col-sm-12 text-center MB-M-48">
							<p className="footer-p-es">Organização:</p>
							<a href='https://institutovertere.org/' target='_blank'><img src={Vertere} /></a>
						</div>
						<div className="col-lg-2 col-sm-12 text-center">
							<p className="footer-p-es">Apoio:</p>
							<a href='https://fractaltecnologia.com.br/' target='_blank'><img src={Fractal} /></a>
						</div>
					</div>
				</div>
			</section>
    </Fragment>
  );
};

export default Finalistas;
